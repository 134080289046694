<template>
  <v-container>
    <v-select
      density="compact"
      variant="outlined"
      v-model="aggregation"
      :items="aggregationOptions"
      label="Period"
    ></v-select>

    <div class="chart-container">
      <canvas height="300" id="timelineChart"></canvas>
    </div>
  </v-container>
</template>

<script>
import { ref, watch, onMounted } from 'vue';
import Chart from 'chart.js/auto';
import api from '@/services/api';

export default {
  name: 'TimelineChart',
  setup() {
    const aggregation = ref('Daily'); // Default to Daily
    const aggregationOptions = ['Daily', 'Weekly', 'Monthly', 'Quarterly'];
    const events = ref([]);
    let chartInstance = null;

    const includedTypes = ['Created Contact', 'Created Task', 'Created Employee', 'Task Completed'];

    const fetchTimelineData = async () => {
      try {
        const response = await api.get('/dashboard/GetTimelineEventsByEmployeeId');
        events.value = response.data || [];
        aggregateAndRenderData();
      } catch (err) {
        console.error('Error fetching timeline events:', err);
      }
    };

    const aggregateDataByType = (events, period) => {
      const eventTypes = includedTypes;
      const datasets = [];

      const eventDates = events.map(event => new Date(event.date));
      const minDate = new Date(Math.min(...eventDates));
      const maxDate = new Date(Math.max(...eventDates));
      const maxPoints = period === 'Daily' ? 10 : period === 'Weekly' ? 10 : period === 'Monthly' ? 12 : 8;

      const timeRange = getTimeRange(minDate, maxDate, period, maxPoints);

      eventTypes.forEach(type => {
        const filteredEvents = events.filter(event => event.type === type || (type === 'Task Completed' && event.type === 'Completed'));
        const counts = {};

        // Initialize counts for all time range labels
        timeRange.forEach(date => {
          const label = formatDateLabel(date, period);
          counts[label] = 0;
        });

        // Count events for each time range label
        filteredEvents.forEach(event => {
          const date = new Date(event.date);
          const label = formatDateLabel(date, period);
          if (counts[label] !== undefined) {
            counts[label]++;
          }
        });

        const labels = timeRange.map(date => formatDateLabel(date, period));
        const data = labels.map(label => counts[label]);

        datasets.push({
          label: type,
          data: data,
          borderColor: getEventColor(type),
          borderWidth: 2,
          pointRadius: 3,
        });
      });

      const labels = timeRange.map(date => formatDateLabel(date, period));
      return { labels, datasets };
    };

    const getTimeRange = (minDate, maxDate, period, maxPoints) => {
      const range = [];
      const currentDate = new Date(maxDate);

      while (range.length < maxPoints) {
        range.unshift(new Date(currentDate));
        switch (period) {
          case 'Daily':
            currentDate.setDate(currentDate.getDate() - 1);
            break;
          case 'Weekly':
            currentDate.setDate(currentDate.getDate() - 7);
            break;
          case 'Monthly':
            currentDate.setMonth(currentDate.getMonth() - 1);
            break;
          case 'Quarterly':
            currentDate.setMonth(currentDate.getMonth() - 3);
            break;
        }
        if (currentDate < minDate) break;
      }
      return range;
    };

    const formatDateLabel = (date, period) => {
      switch (period) {
        case 'Daily':
          return date.toISOString().split('T')[0];
        case 'Weekly': {
          const weekStart = new Date(date);
          weekStart.setDate(date.getDate() - date.getDay());
          return weekStart.toISOString().split('T')[0];
        }
        case 'Monthly':
          return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
        case 'Quarterly': {
          const quarter = Math.floor(date.getMonth() / 3) + 1;
          return `${date.getFullYear()} Q${quarter}`;
        }
        default:
          return date.toISOString().split('T')[0];
      }
    };

    const getEventColor = eventType => {
      switch (eventType) {
        case 'Created Contact':
          return 'rgba(41, 121, 255, 0.6)';
        case 'Created Task':
          return 'rgba(123, 31, 162, 0.6)';
        case 'Created Employee':
          return 'rgba(0, 191, 165, 0.6)';
        case 'Task Completed':
          return 'rgba(0, 128, 0, 0.6)';
        default:
          return 'rgba(0, 0, 0, 0.6)';
      }
    };

    const renderChart = (labels, datasets) => {
      const ctx = document.getElementById('timelineChart').getContext('2d');
      if (chartInstance) {
        chartInstance.destroy();
      }

      // Filter out datasets with all 0 values
      const filteredDatasets = datasets.filter(dataset => dataset.data.some(value => value > 0));

      chartInstance = new Chart(ctx, {
        type: 'line',
        data: {
          labels: labels,
          datasets: filteredDatasets,
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true,
              grace: '5%',
              ticks: {
                callback: function (value) {
                  return value; // Ensure only unique values appear
                },
                autoSkip: true, // Automatically skip redundant ticks
                stepSize: 1, // Ensure the ticks increment properly
              },
            },
            x: {
              ticks: {
                maxTicksLimit: 10,
              },
            },
          },
          plugins: {
            legend: {
              display: true,
              position: 'top',
            },
          },
        },
      });
    };


    const aggregateAndRenderData = () => {
      const { labels, datasets } = aggregateDataByType(events.value, aggregation.value);
      renderChart(labels, datasets);
    };

    watch(aggregation, aggregateAndRenderData);

    onMounted(() => {
      fetchTimelineData();
    });

    return {
      aggregation,
      aggregationOptions,
      events,
    };
  },
};
</script>

<style scoped>
.chart-container {
  position: relative;
  width: 100%;
  height: 590px;
}
</style>
