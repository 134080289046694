<template>
  <DialogBase :isDialogVisible="dialogOpen">
    <template v-slot:header>
      <span>Create New Employee</span>
    </template>

    <template v-slot:content>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col cols="12" class="pt-2">
            <p><strong>Personal Information</strong></p>
          </v-col>
          <v-col cols="6" class="pb-1 pt-1 pr-1">
            <v-text-field
              v-model="newEmployee.firstName"
              label="First Name"
              variant="outlined"
              density="compact"
              :rules="[rules.required]"
            ></v-text-field>
          </v-col>
          <v-col cols="6" class="pb-1 pt-1 pl-1">
            <v-text-field
              v-model="newEmployee.lastName"
              label="Last Name"
              variant="outlined"
              density="compact"
              :rules="[rules.required]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="pb-1 pt-1">
            <v-select
              @update:modelValue="newEmployee.titleId = ''"
              :items="employeeDepartments"
              v-model="newEmployee.departmentId"
              item-title="department"
              item-value="employee_Department_ID"
              variant="outlined"
              density="compact"
              label="Department"
              :rules="[rules.required]"
            ></v-select>
          </v-col>
          <v-col cols="12" class="pb-1 pt-1">
            <v-select
              :disabled="newEmployee.departmentId === ''"
              :items="filteredTitles"
              v-model="newEmployee.titleId"
              item-title="title"
              item-value="employee_Title_ID"
              variant="outlined"
              density="compact"
              label="Title"
              :rules="[rules.required]"
            ></v-select>
          </v-col>
          <v-col cols="8" class="pt-1 pr-1 pb-2">
            <v-text-field
              variant="outlined"
              density="compact"
              v-model="newEmployee.hireDate"
              label="Hire Date"
              type="date"
            ></v-text-field>
          </v-col>

          <v-col cols="4" class="pt-1 pl-1 pb-2">
            <v-select
              :items="shifts"
              v-model="newEmployee.shift"
              item-title="title"
              item-value="key"
              variant="outlined"
              density="compact"
              label="Shift"
              :rules="[rules.required]"
            ></v-select>
          </v-col>
        </v-row>
      </v-form>
    </template>

    <template v-slot:footer>
      <v-btn variant="text" @click="closeDialog">Cancel</v-btn>
      <v-btn
        color="primary"
        append-icon="mdi-plus"
        variant="tonal"
        :loading="isAddBtnLoading"
        :disabled="!valid"
        @click="submitForm"
      >Add Employee</v-btn>
    </template>
  </DialogBase>
</template>

<script>
import api from '@/services/api'
import DialogBase from './DialogBase.vue';

export default {
  name: 'AddEmployeeDialog',
  components: {
    DialogBase
  },
  props: {
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      valid: false,
      isAddBtnLoading: false,
      shifts: [
        { title: '1st Shift', key: '1' },
        { title: '2nd Shift', key: '2' },
        { title: '3rd Shift', key: '3' },
      ],
      employeeTitles: [],
      employeeDepartments: [],
      newEmployee: {
        firstName: '',
        lastName: '',
        shift: '',
        departmentId: '',
        titleId: '',
        hireDate: new Date().toISOString().substr(0, 10),
      },
      rules: {
        required: value => !!value || 'Required.',
        counter: value => value.length <= 60 || 'Max characters reached',
      }
    }
  },
  mounted() {
    this.getEmployeeTitles();
    this.getEmployeeDepartments();
  },
  methods: {
    async getEmployeeTitles() {
      try {
        const response = await api.get('/Employee/GetEmployeeTitles');
        if (response.status === 200) {
          this.employeeTitles = response.data.sort((a, b) => a.title.localeCompare(b.title));
          return true;
        } else {
          console.log('Error fetching employee titles.');
          return false;
        }
      } catch (error) {
        console.error('API error occured while getting titles:', error);
        return false;
      }
    },
    async getEmployeeDepartments() {
      try {
        const response = await api.get('/Employee/GetEmployeeDepartments');
        if (response.status === 200) {
          this.employeeDepartments = response.data.sort((a, b) => a.department.localeCompare(b.department));
          return true;
        } else {
          console.log('Error fetching employee departments.');
          return false;
        }
      } catch (error) {
        console.error('API error occured while getting departments:', error);
        return false;
      }
    },

    closeDialog() {
      this.dialogOpen = false;
      this.resetForm();
    },
    async submitForm() {
      this.isAddBtnLoading = true;

      const employee = {
        First_Name: this.newEmployee.firstName,
        Last_Name: this.newEmployee.lastName,
        Shift: this.newEmployee.shift,
        Title_ID: this.newEmployee.titleId,
        Department_ID: this.newEmployee.departmentId
      };

      try {
        await api.post('/Employee/AddEmployee', employee);
        await new Promise(resolve => setTimeout(resolve, 500));

        this.showSnackbar('success', 'mdi-check-circle', 'Successfully Created Employee!', 'Added ' + employee.First_Name + ' ' + employee.Last_Name + ' to employees.');
        this.refreshParentCollection();
        this.closeDialog();
      } catch (error) {
        console.error(error);
        this.showSnackbar('error', 'mdi-alert-circle', 'Unable to Create Contact', 'An error occured while attempting to create new contact.');
      } finally {
        this.isAddBtnLoading = false;
      }
    },
    resetForm() {
      this.$refs.form.reset();
    },
    refreshParentCollection() {
      this.$emit('refresh-employees');
    },
    showSnackbar(color, icon, title, description) {
      this.$emit('show-snackbar', {
        color,
        icon,
        title,
        description,
        timeout: 5000,
      });
    },
  },
  computed: {
    dialogOpen: {
      get() {
        return this.isDialogOpen;
      },
      set(value) {
        this.$emit('update:isDialogOpen', value);
      }
    },
    filteredTitles() {
      return this.employeeTitles.filter(
        title => title.department_ID === this.newEmployee.departmentId
      );
    }
  }
}
</script>
